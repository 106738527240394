import React from "react";
import FormHeader from "../components/form/FormHeader";
import {useSelector} from "react-redux";
import {RootState} from "../store/reducers";
import {Layout} from "../components/Layout";
import {FormLayout} from "../components/form/FormLayout";
import {FORM_API_SERVICE_IDS} from "../constants";
import {RegisterPage} from "../store/models/register-page";
import FormSuccessBlock from "../components/form/FormSuccessBlock";
import SEO from "../components/SEO";
import {FormSuccessModel} from "../store/models/form-success";
import StrapiClient, {StrapiResponse, StrapiSingleTypes} from "../strapi-client/strapi-client";
import {MenuModel} from "../store/models/menu";
import {GetServerDataProps, GetServerDataReturn} from "gatsby";
import {FormStatus} from "../store/types/form-service.types";

type RegisterCompaniesPageProps = {
    serverData: {
        menu: StrapiResponse,
        page: StrapiResponse,
        successPage:  StrapiResponse
    },
    location: any
}

const RegisterCompanies = (props: RegisterCompaniesPageProps) => {
    const location = props.location;
    const registerPageModel: RegisterPage = RegisterPage.fromJSON(props.serverData.page);
    const menuModel: MenuModel = MenuModel.fromJSON(props.serverData.menu);
    const formSuccessModel: FormSuccessModel = FormSuccessModel.fromJSON(props.serverData.successPage);
    const status = useSelector((state: RootState) => state.formService.status);

    if (status === FormStatus.FORM_STATUS_SUBMITTED) {
        return (
            <Layout noFooter menu={menuModel}>
                <SEO seo={registerPageModel.seo}></SEO>
                <FormSuccessBlock
                    title={formSuccessModel.title || ''}
                    subtitle={formSuccessModel.subtitle || ''}
                    socialTitle={formSuccessModel.socialTitle || ''}
                    email={formSuccessModel.email || ''}
                    socials={formSuccessModel.socials}
                ></FormSuccessBlock>
            </Layout>
        );
    }

    return (
        <Layout  menu={menuModel}>
            <SEO seo={registerPageModel.seo}></SEO>
            <FormHeader pathname={location.pathname} title={registerPageModel.title} links={registerPageModel.links} />
            <FormLayout formIcon={registerPageModel.formIcon?.fullUrl} formUuid={FORM_API_SERVICE_IDS.org} submitTitle={registerPageModel.submitTitle || ''}>

            </FormLayout>
        </Layout>
    )

}

export default RegisterCompanies

export async function getServerData(context: GetServerDataProps): GetServerDataReturn  {
    try {
        const locale: string = context.pageContext.locale as string;
        const strapi = new StrapiClient(locale);
        const page = await strapi.getSingleType(StrapiSingleTypes.signUpForCompaniesPage);
        const successPage = await strapi.getSingleType(StrapiSingleTypes.successPage);
        const menu = await strapi.getSingleType(StrapiSingleTypes.menu);

        return {
            props: {
                page,
                menu,
                successPage
            },
        }
    } catch (error) {
        return {
            status: 500,
            headers: {},
            props: {}
        }
    }
}
